import React, { useEffect } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import secondBlog from "../images/secondBlog.png";

function SecondBlog(props) {
  useEffect(() => {
    const title = "HOME | CHEF REPUBLIC";
    const desc =
      "On Demand Home Chef Service, for Personalized Meal Prep at an affordable rate. Book a home chef to come to your home with fresh groceries and cook/meal prep healthy homemade meals for you and your family for up to 20 servings, catered to your preference and taste.";

    document.title = title;

    const metaTitle = document.querySelector("meta[name='title']");
    const metaDescription = document.querySelector("meta[name='description']");
    const metaTitleOG = document.querySelector("meta[property='og:title']");
    const metaDescriptionOG = document.querySelector(
      "meta[property='og:description']"
    );
    const metaTitleTwitter = document.querySelector(
      "meta[property='twitter:title']"
    );
    const metaDescriptionTwitter = document.querySelector(
      "meta[property='twitter:description']"
    );

    if (metaTitle) metaTitle.setAttribute("content", title);
    if (metaDescription) metaDescription.setAttribute("content", desc);
    if (metaTitleOG) metaTitleOG.setAttribute("content", title);
    if (metaDescriptionOG) metaDescriptionOG.setAttribute("content", desc);
    if (metaTitleTwitter) metaTitleTwitter.setAttribute("content", title);
    if (metaDescriptionTwitter)
      metaDescriptionTwitter.setAttribute("content", desc);
  }, []);

  return (
    <>
      <Nav customer={props.customer} home={true} />

      <div className="container">
        <p className="fw-bold mt-4" style={{ fontSize: "2rem" }}>
          Home Dining Experience with Chef Republic’s On-Demand Chef Service
        </p>
        <div
          id="firstBlogs"
          style={{
            background: `url(${secondBlog})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></div>

        <p className="mt-3">
          Do you want the same old home-cooked meals or need help finding the
          time to prepare delicious dishes? Say goodbye to kitchen stress and
          hello to gourmet dining with Chef Republic’s on-demand chef service!
        </p>
        <p>
          {" "}
          Imagine enjoying restaurant-quality meals right in the comfort of your
          own home. Chef Republic brings professional chefs to your kitchen,
          turning your dining experience into a luxurious event. Whether you're
          hosting a family dinner or birthday party or just want to indulge in a
          special meal, their chefs cater to your needs.
        </p>
        <h4>
          Chef Republic’s On-Demand Chef Service to Upgrade Your Home Dining
          Experience
        </h4>
        <b>Quick Summary</b>
        <ul>
          <li>Chef Republic’s On-Demand Chef Service Upgrade Your Home Dining </li>
          <li>Why Choose Chef Republic? </li>
          <li>Expert and Affordable Service</li>
          <li>How It Works Make</li>
          <li>Your Next Meal Extraordinary</li>
        </ul>
        <h4>Why Choose Chef Republic?</h4>
        <p>
        At Chef Republic, we’re all about customising a dining explore dining experience time to understand your preferences, dietary restrictions, and special occasions to create a custom menu. Whether hungry for vegetarian delights or craving exquisite seafood, our diverse menu options ensure something for every taste bud.

        </p>
        <p>
        With Chef Republic, we handle everything from start to finish, so literally, you are ending up with the stress of grocery shopping and cleaning up.so you can simply sit back, relax, and enjoy a delicious meal without lifting a finger.

        </p>

        <h4>Expert and Affordable Service  </h4>
        <p>
        Our team of highly skilled chefs brings their creativity and expertise to every dish. Each meal is prepared with precision and care, guaranteeing a gourmet experience that will delight your senses.

        </p>
        <p>
        From planning your personalised menu to serving the meal and managing all the cleanup, Chef Republic offers a seamless, hassle-free dining experience. You focus on enjoying your meal while we take care of the details.
        </p>

        <p>
        Experience the elegance of fine dining at home without the hefty price tag of dining out. The Chef Republic provides an affordable way to enjoy gourmet meals right in the comfort of your home, making luxury dining accessible and enjoyable.
        </p>
        

        <h4>How It Works</h4>
        <p>
        Select a date and time that works best for you, and we’ll handle the rest. It’s that easy to get started with Chef Republic’s on-demand service.
        </p>
        <p>
        When the day arrives, the chef will come to your home to prepare and serve your meal. All you need to do is sit back, relax, and take every delicious bite.  </p>
        <p>
        After your meal, the chef will take care of all the clean-up, so you’ll be left with nothing but to enjoy your meal and create beautiful memories
        </p>


<h4>    Make Your Next Meal Extraordinary</h4>
    <p>Chef Republic’s on-demand chef service is perfect for those who want to elevate their home dining experience without the hassle of cooking. Ready to make your next meal extraordinary? Book with Chef Republic today and discover the joy of gourmet dining at home!
    </p>
    <p>Contact Chef Republic now to schedule your on-demand chef service and transform your next meal into a memorable experience!
    </p>
      </div>

      <Footer customer={props.customer} home={true} />
    </>
  );
}

export default SecondBlog;